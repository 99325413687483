import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import styled from "styled-components"
import { IoIosMenu } from "react-icons/io"

import NavMenu from "./NavMenu"

const Header = ({ siteTitle, location }) => {
  const [isOpen, setNav] = useState(false)
  const toggleNav = () => {
    setNav(isOpen => !isOpen)
  }

  const navState = isOpen ? "showMenu" : ""

  const rootPath = `${__PATH_PREFIX__}/`
  let title

  if (location.pathname === rootPath) {
    title = null
  } else {
    title = (
      <SiteTitle>
        <Link to="/">{siteTitle}</Link>
      </SiteTitle>
    )
  }

  return (
    <HeaderWrapper>
      <header>
        <MobileHeader>
          <div>{title}</div>
          <StyledMenuIcon onClick={toggleNav} className={navState} />
          <OverlayNav className={navState}>
            <NavMenu onClick={toggleNav} />
          </OverlayNav>
        </MobileHeader>
        <DesktopHeader>
          <div>{title}</div>
          <NavMenu />
        </DesktopHeader>
      </header>
    </HeaderWrapper>
  )
}

const HeaderWrapper = styled.div`
  max-width: 100vw;
  height: 5vh;
  @media screen and (min-width: 700px) {
    max-width: 20vw;
    height: 100%;
  }
`

const MobileHeader = styled.div`
  display: block;
  width: 100vw;
  height: 10vh;
  @media screen and (min-width: 700px) {
    display: none;
  }
`

const StyledMenuIcon = styled(IoIosMenu)`
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 100;
  font-size: 2rem;
  :hover {
    cursor: pointer;
  }
`

const OverlayNav = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  background-color: whitesmoke;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  &.showMenu {
    display: flex;
    opacity: 1;
  }
  @media screen and (min-width: 700px) {
    display: none;
  }
`

const SiteTitle = styled.h1`
  font-family: "Mukta", sans-serif;
  font-weight: 400;
  font-size: 3vh;
  letter-spacing: 0px;
  margin: 4vw 5vw;
  @media screen and (min-width: 700px) {
    margin: -3px 1vh 2vh 0;
    line-height: 2.8vh;
    font-size: 2.5vh;
  }
`

const DesktopHeader = styled.div`
  display: none;
  max-width: 400px;
  width: 20vw;
  height: 100%;
  margin-top: 5.4vh;
  @media screen and (min-width: 700px) {
    display: block;
  }
`

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
