import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import Header from "./header"
import Footer from "./footer"
import "./layout.css"

const Layout = ({ location, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const rootPath = `${__PATH_PREFIX__}/`
  let footer
  let header

  if (location.pathname === rootPath) {
    header = null
    footer = null
  } else {
    header = (
      <Header siteTitle={data.site.siteMetadata.title} location={location} />
    )
    footer = <Footer />
  }

  return (
    <LayoutContainer>
      <ContentContainer>
        {header}
        <MainContent>{children}</MainContent>
      </ContentContainer>
      {footer}
    </LayoutContainer>
  )
}

const LayoutContainer = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  @media screen and (min-width: 700px) {
    width: 90vw;
    margin: 0 auto;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  @media screen and (min-width: 700px) {
    flex-direction: row;
  }
`

const MainContent = styled.main`
  width: 100vw;
  height: 100%;
  @media screen and (min-width: 700px) {
    max-width: 60vw;
  }
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
