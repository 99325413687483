import { Link, graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import { FaInstagram, FaTwitter } from "react-icons/fa"

const getSocialUrl = graphql`
  query {
    socialUrl: contentfulContactPage {
      instagram
      twitter
    }
  }
`

const NavMenu = () => {
  const response = useStaticQuery(getSocialUrl)
  const socials = response.socialUrl

  return (
    <ul>
      <Link to="/">
        <MenuItem>Home</MenuItem>
      </Link>
      <Link to="/works/">
        <MenuItem>Works</MenuItem>
      </Link>
      <Link to="/about/">
        <MenuItem>About</MenuItem>
      </Link>
      <Link to="/contact">
        <MenuItem>Contact</MenuItem>
      </Link>
      <MenuItem className="social-links">
        <span className="social-icon">
          <a href={socials.instagram} target="_blank" rel="noopener noreferrer">
            <FaInstagram className="social-icon" />
          </a>
        </span>
        <span className="social-icon">
          <a href={socials.twitter} target="_blank" rel="noopener noreferrer">
            <FaTwitter className="social-icon" />
          </a>
        </span>
      </MenuItem>
    </ul>
  )
}

const MenuItem = styled.li`
  list-style: none;
  margin: 0 0 20px 0;
  font-size: 3vh;
  letter-spacing: 1px;
  width: 120px;
  text-align: center;
  &.social-links {
    margin-top: 40vh;
    display: flex;
    flex-direction: row;
    justify-content: space;
    align-items: center;
    .social-icon {
      margin: 0 auto;
    }
  }
  @media screen and (min-width: 700px) {
    margin: 0 0 1vh 0;
    font-size: 1.5vh;
    text-align: left;
    width: 40px;
    &.social-links {
      margin-top: 3vh;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .social-icon {
        margin: 0;
      }
    }
  }
`

export default NavMenu
