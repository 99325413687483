import React from "react"
import styled from "styled-components"

const Footer = () => (
  <FooterWrapper>
    <StyledFooter>
      © {new Date().getFullYear()}, Mitsunori Nakata All rights reserved.
    </StyledFooter>
  </FooterWrapper>
)

const FooterWrapper = styled.div`
  margin-top: auto;
  height: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 5vw;
`

const StyledFooter = styled.footer`
  margin-top: 5vh;
  font-size: 0.8rem;
  letter-spacing: 0.5px;
  color: gray;
`

export default Footer
